// src/components/MetaTag.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { containsSlug } from '../utils/urlUtils';
import { Location } from '@reach/router';

interface MetaTagProps {
  slugs: string[];
}

const MetaTag: React.FC<MetaTagProps> = ({ slugs }) => {
  return (
    <Location>
      {({ location }) => {
        const shouldAddNoIndex = slugs.some(slug => containsSlug(location.pathname, slug));

        if (shouldAddNoIndex) {
          return (
            <Helmet>
              <meta name="robots" content="noindex, nofollow" />
            </Helmet>
          );
        } else {
          return null;
        }
      }}
    </Location>
  );
};

export default MetaTag;
