// gatsby-browser.js
import React from 'react';
import ExternalLink from './src/components/ExternalLink';
import MetaTag from './src/components/MetaTag';

const slugs = [
'casual/chess/learn/learn-all-about-the-dutch-defense/',
'casual/8-ball-pool/origins-of-pool-billiards-and-snooker-hindi/',
'casual/learn-cricket/10-best-t20-batsman-of-all-time/',
'casual/learn-cricket/top-10-best-bowling-figures-in-t20-cricket/',
'rummy/rmg/rummy-expert-club-withdrawal/',
'casual/learn-cricket/top-10-players-with-most-test-centuries-in-cricket/',
'rummy/learn/what-is-open-deck-and-closed-deck-rummy/',
'rummy/rmg/rummy-circle-login-registration-process-to-get-started/',
'rummy/rmg/apps-websites-similar-to-jackpot-city/',
'casual/chess/learn/scotch-gambit/',
'rummy/learn/learn-29-card-rummy-everything-you-need-to-know/',
'real-money-gaming-apps/how-to-withdraw-and-deposit-money-from-my-team-11/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-zapak/',
'casual/learn-cricket/top-10-cricketers-to-take-50-wickets-in-odi-cricket/',
'casual/chess/learn/chess-italian-opening/',
'casual/8-ball-pool/what-are-the-best-8-ball-pool-tournaments-and-its-rules/',
'casual/learn-cricket/player-to-take-fastest-50-wickets-in-test-cricket/',
'casual/learn-cricket/top-5-fastest-century-in-ipl/',
'casual/chess/learn/want-to-know-best-opening-for-chess/',
'gambling/basketball-betting-lines-tips-and-strategy/',
'casual/chess/learn/best-tips-tricks-tactics-and-strategy-for-chess/',
'gambling/best-fastest-payout-online-casino-to-play-and-earn-money/',
'gambling/best-fastest-payout-online-casino-to-play-and-earn-money/',
'rummy/rmg/casumo-bonus-code/',
'gambling/the-most-complete-casino-guide-to-learn-the-game/',
'rummy/rmg/how-to-download-register-login-rummy-star/',
'casual/8-ball-pool/best-last-and-opening-shots-in-pool/',
'casual/learn-cricket/fastest-to-200-wickets-in-odi-cricket/',
'rummy/rmg/best-casumo-tricks/',
'casual/8-ball-pool/term-book-for-pool-snooker-and-billiards/',
'real-money-gaming-apps/best-carrom-club-hack/',
'casual/learn-cricket/most-wickets-in-womens-test-cricket/',
'rummy/rmg/how-to-withdraw-deposit-money-from-gully-rummy/',
'casual/learn-cricket/top-10-cricket-best-moments/',
'rummy/learn/rummy-variants-hindi/',
'casual/carrom/learn/winning-strategy-carrom/',
'casual/carrom/learn/rules-of-due-in-carrom-hindi/',
'casual/chess/learn/all-you-need-to-know-about-king-moves-in-chess/',
'real-money-gaming-apps/best-nostra-pro-hack/',
'real-money-gaming-apps/best-qureka-hack/',
'casual/chess/learn/how-to-win-chess-in-2-moves/',
'casual/learn-cricket/top-5-players-to-score-the-most-runs-in-a-debut-test-match/',
'rummy/rmg/how-to-download-register-login-on-win-101-rummy/',
'casual/chess/learn/chess-middlegames/',
'real-money-gaming-apps/how-to-withdraw-and-deposit-money-from-ballebaazi/',
'casual/chess/learn/how-to-play-chinese-chess-read-here/',
'casual/learn-cricket/all-about-big-bash-fantasy-league/',
'rummy/rmg/how-to-download-register-login-rummy-mania/',
'rummy/rmg/rummy-culture-customer-care/',
'rummy/rmg/how-to-withdraw-cash-from-rummy-culture/',
'casual/chess/learn/fundamentals-of-positional-chess/',
'gambling/which-betting-sites-accept-paytm/',
'rummy/learn/indian-rummy-marathi/',
'rummy/rmg/rummy-leopard-withdrawal-how-to-withdraw-and-deposit/',
'rummy/rmg/how-to-change-personal-details-in-rummy-circle/',
'rummy/learn/indian-rummy-telugu/',
'real-money-gaming-apps/cricket-withdrawal-how-to-withdraw-and-deposit-money/',
'gambling/what-is-gambling-meaning-and-types/',
'casual/chess/learn/know-about-chess-variants/',
'casual/learn-cricket/t20-world-cup-list-champions-and-runner-ups/',
'casual/chess/learn/giuoco-piano-opening-all-you-need-to-know-about-it/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-andar-bahar/',
'real-money-gaming-apps/fan2play-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/how-to-download-register-login-rummy-ho/',
'casual/chess/learn/want-to-learn-about-special-chess-moves-and-rules/',
'casual/learn-cricket/top-10-best-bowling-figures-in-odi/',
'casual/chess/learn/everything-about-the-king-pawns-opening/',
'casual/chess/learn/chess-for-pc-best-games-to-play-online/',
'rummy/rmg/how-to-download-login-and-register-on-rummy-city/',
'casual/chess/learn/how-to-win-a-chess-tournament-tips-tricks/',
'casual/chess/learn/trompowsky-attack/',
'casual/learn-cricket/fastest-bowlers-to-take-400-wickets-in-odi-cricket/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-khelyaar/',
'real-money-gaming-apps/how-to-withdraw-and-deposit-money-from-11-wickets/',
'casual/chess/learn/benko-gambit/',
'casual/learn-cricket/how-do-womens-test-cricket-match-differ-from-mens-test-cricket-match/',
'real-money-gaming-apps/how-to-download-login-sign-in-and-register-blitzpools/',
'rummy/rmg/how-to-download-login-and-register-on-rummy-shark/',
'rummy/rmg/rummy-loot-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/rummy-time-customer-number-chat-support/',
'casual/learn-cricket/fastest-to-800-wickets-in-test-cricket/',
'rummy/rmg/how-to-download-login-and-register-on-octro-rummy/',
'rummy/rmg/similar-a23-rummy-apps/',
'real-money-gaming-apps/websites-and-apps-like-fan-duel/',
'casual/chess/learn/how-to-win-chess-in-2-moves/',
'casual/learn-cricket/top-5-players-to-take-fastest-200-wickets-in-test-cricket/',
'rummy/rmg/how-to-download-register-and-login-on-win-101-rummy/',
'casual/chess/learn/chess-middlegames/',
'real-money-gaming-apps/how-to-withdraw-and-deposit-money-from-leaguex/',
'casual/chess/learn/how-to-play-chinese-chess-read-here/',
'casual/learn-cricket/how-does-the-ipl-auction-work/',
'casual/chess/learn/want-to-learn-about-chess-bird-opening/',
'real-money-gaming-apps/faboom-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/cheer-rummy-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/royal-panda-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/rummy-palace-withdrawal-how-to-withdraw-and-deposit-money/',
'gambling/what-is-arbitrage-betting/',
'rummy/rmg/how-to-play-to-a23-cash-rummy-and-win-real-cash-2/',
'rummy/rmg/how-to-download-register-and-login-khush-rummy/',
'casual/learn-cricket/10-best-test-batsman-of-all-time-in-cricket-history/',
'rummy/rmg/how-to-download-ace2three-plus-rummy-app/',
'rummy/rmg/how-to-download-login-and-register-on-3a-rummy-apk/',
'rummy/rmg/win-101-rummy-withdrawal-how-to-withdraw-and-deposit-money/',
'gambling/how-many-types-of-betting-are-there-learn-everything-here-hindi/',
'rummy/learn/indian-rummy-gujarati/',
'real-money-gaming-apps/best-taptap-hack/',
'real-money-gaming-apps/best-fan-duel-strategy-for-you-to-play-and-earn-money/',
'real-money-gaming-apps/best-fan-duel-strategy-for-you-to-play-and-earn-money/',
'rummy/rmg/rummy-star-withdrawal-how-to-withdraw-and-deposit-money/',
'casual/8-ball-pool/how-to-play-bumper-pool/',
'rummy/rmg/download-teen-patti-winner-apk-signup-bonus-21-cash/',
'rummy/rmg/similar-app-gin-rummy-plus/',
'casual/learn-cricket/how-do-womens-test-cricket-match-differ-from-mens-test-cricket-match/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-ludo-cash/',
'rummy/rmg/how-to-download-register-and-login-on-rummy-guru-apk/',
'casual/8-ball-pool/what-are-baulkline-and-straight-line-rail/',
'casual/learn-cricket/best-powerplays-in-the-ipl/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-qrumble/',
'rummy/rmg/how-to-download-login-register-rummy-supreme-apk/',
'rummy/rmg/how-to-download-login-and-register-on-rummy-sunday-apk/',
'casual/learn-cricket/top-10-players-to-take-the-fastest-400-wickets-in-test/',
'casual/learn-cricket/most-wickets-in-odi-debut-match/',
'real-money-gaming-apps/best-zapak-hack/',
'rummy/rmg/get-rs-55-sign-up-bonus-download-login-rummy-one/',
'poker/rmg/best-adda52-hack-for-you-to-play-and-earn-money/',
'real-money-gaming-apps/best-gamegully-hack/',
'rummy/rmg/dhan-rummy-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/rummy-east-offer/',
'hi/rummy/',
'real-money-gaming-apps/websites-and-apps-like-nostra-pro/',
'rummy/rmg/skillclash-coupon-codes/',
'rummy/rmg/rummy-king-withdrawal-how-to-withdraw-and-deposit-money/',
'real-money-gaming-apps/how-to-withdraw-and-deposit-money-from-prime-captain/',
'rummy/rmg/rummy-time-referral-code-and-refer-and-earn-program/',
'real-money-gaming-apps/best-ludope-hack-for-you-to-play-and-earn-money/',
'real-money-gaming-apps/what-are-some-famous-apps-like-gamezop-learn-it-all-here/',
'real-money-gaming-apps/best-ludope-hack-for-you-to-play-and-earn-money/',
'rummy/rmg/rummy-victory-app-login-how-to-download-and-register/',
'real-money-gaming-apps/pocket-league-app-download-login-sign-in-registration-and-more/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-twelfth-man/',
'rummy/rmg/lucky-rummy-promo-codes/',
'real-money-gaming-apps/how-to-download-login-sign-in-and-register-fsl11/',
'casual/chess/learn/how-to-play-english-chess-opening-read-here/',
'casual/8-ball-pool/what-is-cushion-caroms/',
'rummy/rmg/cash-royal-rummy-coupon-codes/',
'rummy/rmg/ace2three-login-process-and-getting-started-with-app/',
'rummy/rmg/oppa888-coupon-codes/',
'real-money-gaming-apps/ludo-empire-download-how-to-register-and-login/',
'real-money-gaming-apps/best-apps-like-halaplay/',
'real-money-gaming-apps/how-to-make-money-online-from-apps-like-cricplay/',
'real-money-gaming-apps/best-bahubali-game-hack-for-you-to-play-and-earn-money/',
'real-money-gaming-apps/best-bahubali-game-hack-for-you-to-play-and-earn-money/',
'rummy/rmg/withdrawal-and-deposit-of-rummy-monster/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-ludo-fantasy/',
'real-money-gaming-apps/sport11-minimum-withdrawal-how-to-withdraw-and-deposit-money/',
'real-money-gaming-apps/best-apps-like-my-team-11/',
'rummy/rmg/how-to-download-register-and-login-rummy-champ/',
'rummy/rmg/how-to-download-login-and-register-on-rummy-villa/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-dotball/',
'rummy/rmg/how-to-download-login-and-register-on-rummy-rafael/',
'rummy/rmg/rummy-go-coupon-codes-and-bonus-offers/',
'rummy/rmg/gentle-rummy-withdrawal-how-to-withdraw-and-deposit-money/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-play-spl/',
'real-money-gaming-apps/best-jeet11-hack/',
'rummy/rmg/silkrummy-withdrawal-how-to-withdraw-and-deposit-money/',
'earn-money/what-are-the-top-10-apps-to-earn-money-while-playing-games-hindi/',
'rummy/rmg/how-to-download-register-and-log-in-to-rummy-elite/',
'rummy/rmg/magic-rummy-withdrawal/',
'rummy/rmg/how-to-download-register-login-rummy-tour/',
'rummy/rmg/how-to-download-login-and-register-on-open-ultimate-rummy/',
'real-money-gaming-apps/best-fanfight-hack-for-you-to-play-and-earn-money/',
'rummy/rmg/rummy-time-online-play-earn-money/',
'real-money-gaming-apps/best-fanfight-hack-for-you-to-play-and-earn-money/',
'real-money-gaming-apps/fanmojo-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/how-to-download-register-and-login-on-aa-rummy/',
'rummy/rmg/rummy-tycoon-withdrawal/',
'rummy/rmg/how-to-download-login-and-register-on-rummy-sun/',
'poker/rmg/the-best-apps-like-nostragamus-to-play-and-earn-money/',
'real-money-gaming-apps/ewar-games-minimum-withdrawal-how-to-withdraw-and-deposit-money/',
'real-money-gaming-apps/how-to-download-log-in-sign-in-and-register-on-cricwars/',
'rummy/rmg/rummy-king-coupon-codes/',
'earn-money/what-are-best-sources-for-extra-income-in-india/',
'rummy/rmg/ruby-rummy-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/download-rummy-365-apk-login-get-rs-365-welcome-bonus/',
'rummy/rmg/r-rummy-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/how-to-download-register-and-log-in-to-rummy-max/',
'rummy/rmg/rummy-ola-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/rummy-hero-withdrawal-how-to-withdraw-and-deposit-money/',
'casual/chess/learn/learn-to-play-chess/',
'casual/learn-cricket/world-most-six-in-cricket/',
'real-money-gaming-apps/how-to-withdraw-deposit-money-from-gamegully/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-fanspole/',
'rummy/rmg/fair-play-policy-of-rummy-circle/',
'gambling/get-to-know-if-gambling-legal-in-india-or-not/',
'gambling/different-types-of-bets-everything-you-need-to-know/',
'rummy/rmg/rummy-yes-coupon-codes/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-earnzee/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-jiyo11/',
'rummy/rmg/rummy-jadoo-withdrawal-how-to-withdraw-and-deposit-money/',
'real-money-gaming-apps/ludope-withdrawal-how-to-deposit-and-withdraw-money/',
'rummy/rmg/how-to-download-login-and-register-on-6ixxer-rummy/',
'rummy/rmg/similar-app-rummy-world/',
'casual/8-ball-pool/what-is-scratch-placement-in-pool-hindi/',
'gambling/the-top-10-horse-jockeys-in-india-and-the-world-hindi/',
'gambling/top-gambling-companies-in-india/',
'rummy/rmg/best-royal-panda-tips/',
'gambling/best-betting-sites-for-you-to-play-and-earn-money/',
'gambling/best-betting-sites-for-you-to-play-and-earn-money/',
'rummy/rmg/how-to-download-login-and-register-on-daily-rummy/',
'real-money-gaming-apps/how-to-withdraw-and-deposit-money-from-jeet11/',
'rummy/rmg/download-lov-rummy-app-and-get-a-41-sign-up-bonus/',
'casual/learn-cricket/top-players-with-most-runs-in-t20-cricket/',
'rummy/rmg/download-trever-rummy-apk-login-get-rs-51-welcome-bonus/',
'gambling/what-is-baccarat-gambling-everything-you-need-to-know/',
'rummy/rmg/ace2three-customer-number-chat-support/',
'casual/8-ball-pool/what-is-cut-throat-pool-hindi/',
'casual/learn-cricket/status-of-cricket-in-olympics/',
'rummy/rmg/winning-tips-tricks-and-hacks-for-octro-rummy/',
'casual/chess/learn/know-about-top-chess-players-2/',
'real-money-gaming-apps/best-halaplay-hack/',
'rummy/rmg/download-okey-rummy-app-get-rs-25-bonus-on-okey-rummy-apk/',
'rummy/learn/rummy-sequence-series-pair-and-set-rules-hindi/',
'casual/8-ball-pool/what-does-hand-in-8-ball-pool-mean-hindi/',
'casual/chess/learn/how-to-master-chess/',
'rummy/rmg/fun-rummy-club-withdrawal-how-to-withdraw-and-deposit-money/',
'real-money-gaming-apps/call-break-multiplayer-apk-download-for-android-ios-and-web/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-criczar/',
'casual/8-ball-pool/artistic-billiards-everything-you-need-to-know/',
'faqs/casual/',
'earn-money/top-10-apps-to-earn-money-online-from-your-home-hindi/',
'real-money-gaming-apps/best-ballebaazi-hack/',
'rummy/rmg/winning-tips-tricks-and-hacks-for-rummy-satta/',
'real-money-gaming-apps/dragon-vs-tiger-apk-download-for-windows-ios-android/',
'rummy/rmg/how-to-download-register-and-log-in-to-333-rummy/',
'rummy/rmg/rummy-sunday-withdrawal-how-to-withdraw-and-deposit/',
'earn-money/top-5-websites-where-you-can-earn-money-online-from-your-home/',
'real-money-gaming-apps/best-cricplay-hack/',
'real-money-gaming-apps/gosuper11-minimum-withdrawal-how-to-withdraw-and-deposit-money/',
'rummy/rmg/rummy-vs-withdrawal-how-to-withdraw-and-deposit/',
'rummy/rmg/rummy-dangal-withdrawal-how-to-withdraw-and-deposit-money/',
'gambling/get-to-know-if-online-betting-legal-in-india-or-not/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-ludo-comfun-2/',
'rummy/rmg/rummy-plus-coupon-codes/',
'earn-money/best-and-original-apps-to-earn-real-money-at-in-india/',
'rummy/rmg/rummy-party-withdrawal-how-to-withdraw-and-deposit-money/',
'earn-money/list-of-methods-for-housewives-to-earn-money-online-hindi/',
'gambling/the-best-sports-betting-sites-online/',
'gambling/what-is-horse-gambling-everything-you-need-to-know/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-khelfactory/',
'rummy/rmg/rummy-loot-offer/',
'rummy/rmg/magic-rummy-coupon-codes/',
'earn-money/5-tips-to-earn-and-save-money-this-diwali/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-rush-by-hike/',
'real-money-gaming-apps/how-to-download-login-and-register-on-ludo-skill-app/',
'rummy/rmg/how-to-download-register-and-log-in-on-rummy-4-u/',
'casual/chess/learn/how-to-win-chess/',
'gambling/betting-odds-learn-its-types-and-functions/',
'real-money-gaming-apps/winning-tips-for-gamee-hindi/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-fantasyji/',
'casual/chess/learn/scotch-opening-chess/',
'casual/chess/learn/best-chess-strategies/',
'earn-money/top-ways-to-earn-money-using-your-mobile-phone/',
'gambling/get-to-know-if-playing-casino-in-india-is-legal-or-not/',
'casual/8-ball-pool/learn/how-to-play-8-ball-pool-the-game/',
'casual/8-ball-pool/learn/how-to-play-chinese-pool/',
'casual/8-ball-pool/learn/what-does-hand-in-8-ball-pool-mean/',
'casual/8-ball-pool/learn/what-is-straight-pool-and-how-to-play-it/',
'casual/8-ball-pool/best-alternatives-to-8-ball-pool-hindi/',
'earn-money/best-and-original-apps-to-earn-real-money-at-in-india-hindi/',
'earn-money/top-game-sites-to-earn-money-online-without-any-investment-hindi/',
'gambling/know-about-the-popular-betting-strategy-here/',
'real-money-gaming-apps/how-to-download-login-sign-in-register-11sixes/'
]; // Add your array of slugs here

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {element}
      <ExternalLink />
      <MetaTag slugs={slugs} />
    </>
  );
};
