import React, { useEffect } from 'react';

interface ExternalLinkProps {
  children: string[];
}

const collectAnchorTags = (element: Element) => {
  const anchorTags = element.querySelectorAll('a');
  anchorTags.forEach(anchor => {
    const href = anchor.getAttribute('href');
    if (href && isExternalLink(href)) {
      anchor.setAttribute('rel', 'nofollow');
    }
  });

  element.childNodes.forEach(node => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      collectAnchorTags(node as Element);
    }
  });
};

const isExternalLink = (href: string) => {
  return (
    (href.startsWith('http://bit.ly/') || href.startsWith('https://')) &&
    !href.startsWith('https://www.getmega.com/') &&
    !href.startsWith('https://getmega.onelink.me/') &&
    !href.startsWith('https://getmegarummy.onelink.me/')
  );
};

const ExternalLink: React.FC<ExternalLinkProps> = ({ children }) => {
  useEffect(() => {
    const setNofollowForExternalLinks = () => {
      if (!Array.isArray(children) || children.length === 0) return;

      children.forEach(html => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        const anchorTags = tempDiv.querySelectorAll('a');
        anchorTags.forEach(anchor => {
          const href = anchor.getAttribute('href');
          if (href && isExternalLink(href)) {
            anchor.setAttribute('rel', 'nofollow');
          }
        });
      });
    };

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            collectAnchorTags(node as Element);
          }
        });
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    setNofollowForExternalLinks();
    collectAnchorTags(document.body);

    return () => {
      observer.disconnect();
    };
  }, [children]); 

  return null; 
};

export default ExternalLink;
