// src/utils/urlUtils.ts
export const containsSlug = (pathname: string, slug: string): boolean => {
  return pathname.includes(slug)
}

export function removeDomain(inputString: string) {
  const url = new URL(inputString)

  return url.pathname + url.searchParams
}

export function formUrlPath(path: string): string {
  return path.replace(/\/+/g, '/')
}
